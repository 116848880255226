import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Logo from "../../images/logo-rex.svg"
import { TypeAnimation } from 'react-type-animation';
import ContactFormTop from "./contactFormTop";
import { Link } from "gatsby";


const FormContext = React.createContext();

const Top = () => {

  const [formState, setFormState] = React.useState(true);
  

  return (
    <div className="min-h-screen relative overflow-hidden">
        <StaticImage quality={100} imgStyle={{ position: "absolute" }}  className="opacity-20 md:opacity-100 absolute inset-0 h-full w-full object-cover z-10" src={'../../images/top-bg.png'} alt="TopBG" />
        <div className="absolute top-0 left-0 w-full z-50">
            <div className="max-w-7xl mx-auto relative my-5 flex justify-between z-50">
              <Logo className="w-40" />
              <div className="menu flex items-center text-xs invisible md:visible">
                <a href="#about-rex" className="px-3">O ReX</a>
                <a href="#recruitment-with-rex" className="px-3 hover:text-purple">Nábor s ReX</a>
                <a href="#how-do-we-operate" className="px-3 hover:text-purple">Jak fungujeme?</a>
                <a href="#recruitment-cost" className="px-3 hover:text-purple">Náklady na nábor</a>
                <a href="#our-clients" className="px-3 hover:text-purple">Důvěřují nám</a>
                <a href="#achievements" className="px-3 hover:text-purple">Realizace</a>
                <a href="#team-rex" className="px-3 hover:text-purple">Team ReX</a>
                <a href="#faq" className="px-3 hover:text-purple">FAQ</a>
              </div>
                <div className='relative group flex flex-col text-xs  invisible md:visible'>
                    <div className="relative bg-white -mt-1 px-5 pr-8 pt-3 pb-2 border-[#C2C2C2] border rounded-full">
                        <StaticImage className="mr-2 -mt-[2px] h-4 w-4" src={'../../images/flags/czech-republic.png'} alt={'CZ'}></StaticImage> CZ
                        <div className="absolute right-4 top-[33%] border-l-black border-4 border-b-black border-l-transparent border-t-transparent rotate-45"></div>
                    </div>
                    <div className="hidden group-hover:block group-active:block absolute top-7 mx-3 py-2 left-0">
                        <Link className='block p-2' to='/'><StaticImage className="mr-2 -mt-[2px] h-4 w-4" src={'../../images/flags/united-kingdom.png'} alt={'UK'}></StaticImage> UK</Link>
                        <Link className='block p-2' to='/hu'><StaticImage className="mr-2 -mt-[2px] h-4 w-4" src={'../../images/flags/hungary.png'} alt={'HU'}></StaticImage> HU</Link>
                        <a className='block p-2' href="https://recruitmentexchange.pl/"><StaticImage className="mr-2 -mt-[2px] h-4 w-4" src={'../../images/flags/poland.png'} alt={'PL'}></StaticImage> PL</a>
                        <Link className='block p-2' to='/sk/'><StaticImage className="mr-2 -mt-[2px] h-4 w-4" src={'../../images/flags/slovakia.png'} alt={'SK'}></StaticImage> SK</Link>
                    </div>
                </div>
        </div>
        </div>
        <div className={formState ? "relative py-32 max-w-7xl mx-auto h-full z-40 grid md:grid-cols-2" : "relative py-32 max-w-7xl mx-auto h-full z-40 grid"}>
              <div className={formState ? "flex flex-col justify-center" : "hidden"}>
                <h1 className="text-5xl md:text-7xl font-bold mb-7 text-dark-gray">Nabírejte zaměstnance  <br/> 
                  
                  <span className="min-h-[80px] block pt-2">
                  {/* <TypeAnimation
                      sequence={[
                        'faster', 
                        3000, // Waits 1s
                        'stronger', 
                        2000, // Waits 2s
                        'harder', 
                        2000, // Waits 2s

                      ]}
                      speed={45}
                      wrapper="span"
                      cursor={true}
                      repeat={Infinity}
                      className='typeanimation bg-gradient-to-br from-purple to-teal text-transparent bg-clip-text after:text-dark-gray after:font-normal'
                    /> */}
                    <span className="bg-gradient-to-br from-purple to-teal text-transparent bg-clip-text">efektivněji</span>
                    </span>
                </h1>
                <p className="text-purple text-xl mb-12"><span class="font-bold">
                Seznamte se s ReX</span> –  moderní platformou,<br/> díky které najdete ty nejlepší kandidáty!</p>
              </div>
              <div className="flex flex-col justify-center items-stretch">
                <div className="flex flex-col items-stretch">
                  <div className="block relative pr-12">
                    <div className="bg-white w-full  border-4 border-purple rounded-[30px] rounded-tl-none p-10 z-10 relative">
                      <ContactFormTop formState={formState}/>
                    </div>
                    <div onClick={() => setFormState(!formState)} className="w-20 cursor-pointer bottom-1 absolute right-0 top-1 flex items-center justify-center bg-gradient-to-r from-purple to-teal rounded-r-[30px]  -ml-15 z-0">
                      <span className="uppercase relative z-40 inline-block font-bold text-white badge-text">
                      {formState ? "CHCI ZNÁT ORIENTAČNÍ CENU ZA ​​SVŮJ NÁBOR »" : "Skrýt další pole »"}</span>
                    </div>
                  </div>
                </div>
              </div>

        </div>
    </div>
  )
}

export default Top