import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Efektywna from '../../images/about-icons/efektywna.svg'
import Tansza from '../../images/about-icons/tansza.svg'
// import Szybsza from '../../images/about-icons/szybsza.svg'
import Nieograniczona from '../../images/about-icons/nieograniczona.svg'


const About = () => {
  return (
    <section id="about-rex" className="pb-40">
        <div className="max-w-7xl mx-auto grid md:grid-cols-2 pt-20 ">
            <div className="z-20 relative ">
                <h2 className="text-4xl font-bold pb-12">O <span className="bg-gradient-to-br from-purple to-teal text-transparent bg-clip-text">ReX</span></h2>
                <p className="text-gray-900 font-light mb-14">
                ReX je inovativní crowdstaffingová platforma, která propojuje klienty, náboráře a kandidáty. ReX náboráři uskuteční i ty nejnestandardnější přijímací řízení. Reagujeme na potřeby klientů a dokazujeme, že nábor nikdy nebyl tak snadný!
                </p>
                
                <a href="#top" className="bg-teal px-10 py-3 rounded-b-3xl rounded-tr-3xl shadow-md shadow-teal inline-block text-center hover:bg-purple hover:text-white hover:shadow-purple mb-20">Začít nábor</a>

                <h3 id="recruitment-with-rex" className="text-3xl font-bold pb-12 text-dark-gray">Nábor s <span className="bg-gradient-to-br from-purple to-teal text-transparent bg-clip-text">ReX</span>  je:</h3>
                <div className="grid md:grid-cols-2 gap-10">
                    <div className="relative">
                        <div className="flex items-end mb-5">
                        <StaticImage className="inline h-12 w-12 mr-5" src={'../../images/about-icons/efektywna.svg'} alt="" />
                        <h3 className="inline font-bold leading-tight">Efektivní</h3>
                        </div>
                        <p className="mb-1 font-light text-dark-gray text-[14px]">Projekty realizují náboráři, kteří znají obor a lokalitu klienta, a mohou tak nacházet vhodné kandidáty</p>
                    </div>
                    <div className="relative">
                        <div className="flex items-end mb-5">
                        <StaticImage className="inline h-12 w-12 mr-5" src={'../../images/about-icons/tansza.svg'} alt="" />
                        <h3 className="inline font-bold leading-tight">Levnější</h3>
                        </div>
                        <p className="mb-1 font-light text-dark-gray text-[14px]">Náklady na nábor jsou v průměru o polovinu nižší a fakturační model je výhodný pro klienta, který platí pouze za zaměstnané kandidáty</p>
                    </div>
                    <div className="relative">
                        <div className="flex items-end mb-5">
                        <StaticImage className="inline h-12 w-16 mr-5" src={'../../images/about-icons/szybsza.svg'} alt="" />
                        <h3 className="inline font-bold leading-tight">Rychlejší</h3>
                        </div>
                        <p className="mb-1 font-light text-dark-gray text-[14px]">Sdružujeme stovky náborářů, okamžitě začínáme jednat a rychle dodáváme první kandidáty</p>
                    </div>
                    <div className="relative">
                        <div className="flex items-end mb-5">
                        <StaticImage className="inline h-12 w-12 mr-5" src={'../../images/about-icons/nieograniczona.svg'} alt="" />
                        <h3 className="inline font-bold leading-tight">Neomezený</h3>
                        </div>
                        <p className="mb-1 font-light text-dark-gray text-[14px]">ReX náboráři působí na mnoha různých místech v několika zemích, díky čemuž mají široké spektrum kandidátů</p>
                    </div>
                </div>    
            </div>
            <div className="flex justify-center items-center relative z-20 m">
                <div className="mt-20 shadow-3xl  bg-white text-center p-10 md:m-20 relative z-10">
                    <StaticImage className="w-36 mb-12" src={'../../images/logo-antal-color.svg'} alt="" />
                    <h3 className="text-purple font-bold uppercase text-lg mb-5">OD SPOLEČNOSTI ANTAL</h3>
                    <p className="text-gray text-lg">Za platformou ReX stojí síla značky Antal. To znamená více než 25 let zkušeností, nejlepší odborníci a záruka nejvyšší kvality.</p>
                </div>
            </div>
        </div>
        
    </section>
  )
}

export default About