import React, { Component } from "react"
import axios from "axios"
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import ReactTooltip from 'react-tooltip';

const API_PATH = "/api-mail-top-cz.php"
const ValidationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Zawartość pola jest zbyt krótka")
    .max(50, "Zawartość pola jest zbyt długa")
    .required("This field is required"),
  email: Yup.string()
    .min(2, "Zawartość pola jest zbyt krótka")
    .max(50, "Zawartość pola jest zbyt długa")
    .email("Nieprawidłowy format adresu e-mail")
    .required("This field is required"),
  phone: Yup.string()
    .min(5, "Zawartość pola jest zbyt krótka")
    .max(15, "Zawartość pola jest zbyt długa"),
  privacy: Yup.bool().oneOf([true], "This field is required."),
  privacy2: Yup.bool().oneOf([true], "This field is required."),
})

export default class ContactFormTop extends Component {


  constructor(props) {
    super(props)
    this.state = {
      name: "",
      email: "",
      phone: "",
      message: "",
      position: "",
      region: "",
      sallary: "",
      description: "",
      mailSent: false,
      error: null,
      privacy: false,
      privacy2: false,
    }
  }

  handleFormSubmit = e => {
    // e.preventDefault()
    axios({
      method: "POST",
      url: `${API_PATH}`,
      headers: { "content-type": "application/json" },
      data: e,
    })
      .then(result => {
        this.setState({
          mailSent: result.data.sent,
          error: result.data.message,
        })
        window.fbq('track', 'Lead');
        window.dataLayer.push({
          event: "conversion",
        })
      })
      .catch(error => this.setState({ error: error.message }))
  }

  

  render() {
    if (this.state.mailSent === false) {
      return (
        <Formik
          initialValues={{
            name: "",
            email: "",
            phone: "",
            message: "",
            position: "",
            region: "",
            sallary: "",
            description: "",
            privacy: false,
            privacy2: false,
          }}
          validationSchema={ValidationSchema}
          onSubmit={values => {
            this.handleFormSubmit(values)
          }}
        >
          {({ isSubmitting }) => (
            <Form className="">
              <h3 className="text-2xl font-bold text-dark-gray"> Podívejte se, jak <span className="bg-gradient-to-br from-purple to-teal text-transparent bg-clip-text">Rex</span> funguje!</h3>
              <div className={this.props.formState ? "grid grid-cols-1 gap-10" : "grid md:grid-cols-2 md:gap-20"}>
                <div>
                  <div className="mb-10">
                    
                    <div className="">
                      <Field className="border-b-2 border-purple mt-4 w-full pt-3 pb-1 text-dark-gray placeholder:text-light-gray placeholder:text-sm outline-none " type="text" name="name" placeholder="Jméno a příjmení"/>
                      <ErrorMessage
                          className="error-msg text-xs"
                          name="name"
                          component="span"
                        />
                    </div>
                    <div className="">
                      <Field className="border-b-2 border-purple mt-4 w-full pt-3 pb-1 text-dark-gray placeholder:text-light-gray placeholder:text-sm outline-none " type="text" name="email" placeholder="Emailová adresa*"/>
                      <ErrorMessage
                          className="error-msg text-xs"
                          name="email"
                          component="span"
                        />
                    </div>
                    <div className="">
                      <Field className="border-b-2 border-purple mt-4 w-full pt-3 pb-1 text-dark-gray placeholder:text-light-gray placeholder:text-sm outline-none " type="text" name="phone" placeholder="Telefonní číslo"/>
                      <ErrorMessage
                          className="error-msg text-xs"
                          name="phone"
                          component="span"
                        />
                    </div>
                    <div className="">
                      <Field className="border-b-2 border-purple mt-4 w-full pt-3 pb-1 text-dark-gray placeholder:text-light-gray placeholder:text-sm outline-none resize-none" type="text" as="textarea" name="message" placeholder="Zpráva" rows="4"/>
                      <ErrorMessage
                          className="error-msg text-xs"
                          name="message"
                          component="span"
                        />
                    </div>
                    </div>
                </div>
                <div id="hidden-fields" className={this.props.formState ? "hidden" : "" }>
                <div className="mb-10">
                    
                    <div className="">
                      <Field className="border-b-2 border-purple mt-4 w-full pt-3 pb-1 text-dark-gray placeholder:text-light-gray placeholder:text-sm outline-none " type="text" name="position" placeholder="Pozice"/>
                      <ErrorMessage
                          className="error-msg text-xs"
                          name="position"
                          component="span"
                        />
                    </div>
                    <div className="">
                      <Field className="border-b-2 border-purple mt-4 w-full pt-3 pb-1 text-dark-gray placeholder:text-light-gray placeholder:text-sm outline-none " type="text" name="region" placeholder="Náborový region"/>
                      <ErrorMessage
                          className="error-msg text-xs"
                          name="region"
                          component="span"
                        />
                    </div>
                    <div className="">
                      <Field className="border-b-2 border-purple mt-4 w-full pt-3 pb-1 text-dark-gray placeholder:text-light-gray placeholder:text-sm outline-none " type="text" name="sallary" placeholder="Hrubý měsíční plat"/>
                      <ErrorMessage
                          className="error-msg text-xs"
                          name="sallary"
                          component="span"
                        />
                    </div>
                    <div className="">
                      <Field className="border-b-2 border-purple mt-4 w-full pt-3 pb-1 text-dark-gray placeholder:text-light-gray placeholder:text-sm outline-none resize-none" type="text" as="textarea" name="description" placeholder="Description" rows="4"/>
                      <ErrorMessage
                          className="error-msg text-xs"
                          name="description"
                          component="span"
                        />
                    </div>
                    </div>
                    
                </div>

                <div className="privacy-policies -mt-10">
                    <div>
                      <label
                        className="text-dark-gray relative pl-7 block text-sm mb-4"
                        htmlFor="privacy"
                        data-tip="Souhlasím se zpracováním svých osobních údajů společností Antal sp. z o.o. se sídlem ve Varšavě (02-566), ul. Puławska 2, zapsanou do registru podnikatelů pod číslem KRS: 0000825336 za účelem vyřízení poptávky. <br>Zároveň prohlašuji, že: <br>1. Jsem si vědom, že poskytnutí mých údajů je dobrovolné a základem tohoto zpracování je můj souhlas; <br>2. Jsem si vědom, že mám právo svůj souhlas kdykoli odvolat; <br>3. Jsem si vědom, že moje osobní údaje budou zpracovávány, dokud bude obsluhována žádost o cenovou nabídku, k čemuž jsem udělil souhlas, nebo dokud nebude tento souhlas odvolán, s podmínkou, že po ukončení obsluhy cenové nabídky nebo odvolání souhlasu mohou být údaje dále zpracovány během promlčecí doby nároků správce a nároků subjektu údajů vůči správci.<br>4. Beru na vědomí, že souhlas lze odvolat zasláním e-mailu na následující adresu: mail@antal.pl;"
                      >
                        Souhlas se zpracováním osobních údajů  <span className="ml-3 bg-neutral-300 h-4 w-4 rounded-full inline-flex justify-center items-center text-white font-serif">i</span>
                      <Field
                        type="checkbox"
                        id="privacy"
                        name="privacy"
                        className="absolute left-0 top-0"
                      />
                        <ErrorMessage
                          className="error-msg text-xs"
                          name="privacy"
                          component="span"
                        />
                      </label>
                      <ReactTooltip arrowColor={"#fff"} html={true} className="policy-tooltip"/>
                    </div>
                    <div>
                      <label
                        className="text-dark-gray relative pl-7 block text-sm mb-5"
                        htmlFor="privacy2"
                        data-tip="Souhlasím se zasíláním obchodních informací od Antal sp. z o.o. prostřednictvím elektronické komunikace ve smyslu zákona ze dne 18. července 2002 o poskytování elektronických služeb (Sb. zákonů 2013 bod 1422), jakož i s tím, aby Antal sp. z o.o. používalo koncová zařízení a automatické volací systémy ve smyslu zákona o telekomunikacích ze dne 16. července 2004 (Sb. zákonů z roku 2014, položka 243). Prohlašuji, že si uvědomuji, že souhlas je dobrovolný. Beru na vědomí, že souhlas lze odvolat zasláním e-mailu na následující adresu: mail@antal.pl"
                      >
                        Souhlas se zasíláním obchodních informací <span className="ml-3 bg-neutral-300 h-4 w-4 rounded-full inline-flex justify-center items-center text-white font-serif">i</span>
                      <Field
                        type="checkbox"
                        id="privacy2"
                        name="privacy2"
                        className="absolute left-0 top-0"
                      />
                        <ErrorMessage
                          className="error-msg text-xs"
                          name="privacy2"
                          component="span"
                        />
                      </label>
                    </div>
                    </div>
              </div>
              
              <div className="flex justify-end py-10">
              <button
                className="bg-teal text-dark-gray text-sm font-bold px-10 pt-4  py-3 rounded-b-3xl rounded-tr-3xl shadow-md shadow-teal inline text-center hover:bg-purple hover:text-white hover:shadow-purple"
                type="submit"
                disabled={isSubmitting}
              >
                Odeslat <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="currentColor" className="ml-1 w-3 h-3 inline-block">
  <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" /> </svg>
              </button>
              </div>
              
            </Form>
          )}
        </Formik>
      )
    } else {
      return (
        <form className="py-24 text-center text-dark-gray">
          <h3 className="text-3xl mb-5">Thank you!</h3>
          <p className="text-xl mb-12">Your message has been sent.</p>
        </form>
      )
    }
  }
}
