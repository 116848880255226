import * as React from "react"
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

const FaqItems = [
    {
        question: "Kdo je ReX náborář?",
        answer: 'Je to profesionální, nezávislý náborář "na volné noze" nebo personální agentura. ReX náboráři procházejí výběrovým procesem ověřování a školení, aby se mohli stát naším partnerem. Po podpisu smlouvy a zkouškách v oblasti zpracování kandidátů a GDPR ReX náboráři realizují přijímací řízení na crowdstaffingové platformě ReX.',
    },
    {
        question: "Kdo je ReX partner?",
        answer: 'ReX partner a ReX náborář jsou často totožné pojmy, ale ve smlouvách a v systému je rozlišujeme na osobu nebo společnost (ReX partner), která je smluvní stranou, a na náboráře (ReX náborář), který skutečně provádí nábor.',
    },
    {
        question: "Kdo je konzultant?",
        answer: 'Konzultant je zaměstnanec společnosti Antal, který odpovídá za zpracování kandidáta v souladu se smlouvou uzavřenou s klientem.',
    },
    {
        question: "Kdo je ReX lídr?",
        answer: 'ReX lídr je osoba, která řídí projekty v oblasti dané specializace. Směrem ke klientovi zodpovídá za komunikaci a vhodný výběr ReX náborářů pro realizaci projektu. Směrem k platformě má za úkol podporovat ReX náboráře a vhodně jim doporučovat projekty v souladu s jejich zkušenostním profilem.',
    },
    {
        question: "Kdo je Key Account Manager?",
        answer: 'Key Account Manager (KAM) je odborník v oblasti náboru v dané oblasti, osoba určená k obsluze klienta a kandidáta v rámci realizované zakázky, který za příplatek poskytuje konzultační a poradenské služby, ověřování přihlášek a koordinaci spolupráce pro daného klienta.',
    },
    {
        question: "Crowdstafing – jak to funguje?",
        answer: 'Úkolem davu (crowd) je reagovat na potřeby jednotlivce nebo firmy. Díky tomu, že několik specializovaných lidí hledá kandidáty v geografickém rozptylu s využitím různých nástrojů a různých databází zároveň, mohou realizovat nábor rychle a efektivně.',
    },
    {
        question: "Jak vypadá proces zadávání zakázky?",
        answer: 'Když obdržíme popis pracovní pozice, jsme schopni předložit cenovou nabídku, po jejím schválení klientem je námi vyplněný zakázkový dokument zaslán přes portál elektronického podpisu ke schválení oběma stranám.',
    },
    {
        question: "Musím se na platformě registrovat?",
        answer: 'Ne. Poptávku, popis pracovní pozice a jakékoli další údaje lze zaslat pomocí formuláře nebo na e-mail karolina@recruitmentexchange.pl',
    },
    {
        question: "Co získám?",
        answer: 'Klient obdrží profily vybraných kandidátů včetně kontaktních údajů, finančních požadavků, výpovědní lhůty, preferované formy spolupráce daného kandidáta, jeho lokalitu a popisné doporučení náboráře spolu s přiloženým CV. Doporučenými kandidáty jsou pouze zájemci o pohovor k dané nabídce, kteří jsou obeznámeni s pracovní pozicí.',
    },
    {
        question: "Kolik to stojí? Kdy proběhne platba?",
        answer: 'Odměna za úspěch je stejná bez ohledu na plat kandidáta. Odhadovanou cenu můžete zjistit u nás na webu ZDE, ověřujeme ji však v kontextu aktuální situace na trhu, potenciálu kandidátů a přiměřenosti požadavků k potenciálnímu zaměstnání. Fakturu vystavujeme v den nástupu uchazeče do práce se 14denní lhůtou splatnosti.',
    },
    
];

const Faq = () => {
  return (
      <div className="overflow-hidden">
    <section id="faq" className="faq pt-0 pb-24 bg-gradient-to-br from-purple to-teal">
        <div className="max-w-7xl mx-auto">
            <h2 className="text-3xl text-center text-white font-bold mb-20">Často kladené dotazy</h2>
        <Accordion allowZeroExpanded="true">
            {FaqItems.map((faqItem) => (
        <AccordionItem className="mb-10">
          <AccordionItemHeading className="bg-white px-8 py-5 pt-6 text-purple shadow-xl relative z-10">
            <AccordionItemButton className="faq-button relative">
              {faqItem.question}
                <div className="arrow border-b-purple border-l-purple w-3 h-3 absolute right-0 top-0 border-[0.35rem] border-r-transparent border-t-transparent -rotate-45 transition-all"></div>
            </AccordionItemButton> 
          </AccordionItemHeading>
          <AccordionItemPanel className="bg-white px-8 py-8  text-dark-gray text-sm">
            {faqItem.answer}
          </AccordionItemPanel>
        </AccordionItem>
            ))}
            <div className="mb-10">
                <div className="bg-white px-8 py-5 pt-6 text-purple shadow-xl relative z-10">
                    <div className="relative flex flex-col md:flex-row justify-between items-center">
                        <h3 className="text-2xl basis-1 mr-4">Máte&nbsp;dotaz?</h3>
                        <div className="bg-gradient-to-r from-purple to-teal basis-full h-[2px] my-4"></div>
                        <a href="mailto:robert.nawrot@recruitmentexchange.pl" className="ml-4 basis-1 bg-teal text-dark-gray text-sm font-bold  px-24 pt-3  py-2 rounded-b-3xl rounded-tr-3xl shadow-md shadow-teal inline text-center hover:bg-purple hover:text-white hover:shadow-purple">
                        Napište&nbsp;nám
                        </a>
                    </div>
                </div>
            </div>

        
      </Accordion>
      </div>
    </section>
      </div>
  )}

  export default Faq