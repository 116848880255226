import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const PriceComparison = () => {

    const [salary, setSalary] = React.useState(0);
    const [cost, setCost] = React.useState(5000);

    // const calculateCost = (salary) => {
    //     let cost = salary * 12/10;
    //     if (cost <= 5000) {
    //         cost = 5000;
    //     }
    //     setCost(cost);
    // }


  return (
    <section className="py-24">
        <div className="max-w-7xl mx-auto shadow-2xl p-20  relative">
            <StaticImage src={'../../images/price-comparison-bg.png'} className="absolute inset-0 " />
        <div className="relative z-10 md:px-40"  id="recruitment-cost">
            <h2 className="text-center text-3xl font-bold text-dark-gray mb-20">Zjistit cenu <span className="bg-gradient-to-br from-purple to-teal text-transparent bg-clip-text">náboru</span></h2>
            <div className="text-center mt-6 mb-10" >
                <h3 className="text-dark-gray text-2xl font-bold text-center mb-5">Zjistit přibližnou cenu</h3>
                <p className="text-md mb-10">Hrubá měsíční mzda:</p>

                <input className="mb-20 border-b outline-none bg-transparent text-center text-xl" type="number" value={salary} onChange={function(e) { setSalary(e.target.value);   }} /> EUR
                <p className="text-md mb-10">Přibližná cena za nábor:</p>

                <div className="text-purple mb-10 font-bold">{((salary * 12)*0.12 <= 1200) ? 1200 : Math.ceil(((salary * 12)*0.12)/100)*100 } EUR</div>

                <a href="/#top" className="bg-teal px-10 py-3 rounded-b-3xl rounded-tr-3xl shadow-md shadow-teal inline-block text-center hover:bg-purple hover:text-white hover:shadow-purple mb-20">Získat detailní cenovou nabídku</a>
                </div>
            <h3 className="text-dark-gray text-2xl font-bold text-center mb-16">Porovnání náborových modelů</h3>
            <table className="price-comparison-table w-full mb-2">
                <tbody>
                <tr className="text-[20px] font-bold">
                <td>&nbsp;</td>
                <td>Tradiční nábor</td>
                <td className="text-purple">Spolupráce s REX</td>
                </tr>
                <tr>
                <td className="text-sm font-bold">Průměrná cena za nábor</td>
                <td>4 500 EUR</td>
                <td>1 500 EUR</td>
                </tr>
                <tr>
                <td className="text-sm font-bold">Počet kandidátů</td>
                <td>3 až 5</td>
                <td>8 až 10</td>
                </tr>
                <tr>
                <td className="text-sm font-bold">Průměrná doba náboru</td>
                <td>4 týdny</td>
                <td>14 dní</td>
                </tr>
                <tr>
                <td className="text-sm font-bold">Dosah</td>
                <td>Záleží na kanceláři</td>
                <td>Neomezený dosah</td>
                </tr>
                </tbody>
                </table>
                <div className="hidden md:block">
                <StaticImage quality={70}   src={'../../images/CZ-poziom.png'} />
                </div>
                <div className="md:hidden">
                <StaticImage quality={70}  src={'../../images/CZ-pion.png'} />
                </div>
                
         </div>
        </div>
    </section>
  )
}

export default PriceComparison