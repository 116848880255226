import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import LogoRex from "../../images/logo-rex.svg"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const Examples = () => {
    let settings = {
        dots: false,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2000,
        infinite: true,
        speed: 600,
        slidesToShow: 3,
        slidesToScroll: 1,
          responsive: [
              {
                  breakpoint: 800,
                  settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      infinite: true,
                  },
              },
              ]
    };


  return (
    <section  className="examples relative pt-52 pb-10 bg-center -mt-40" id="achievements">
        <div className="absolute bg-light-pink w-full left-0 h-1/2 bottom-0">

        </div>
        <div className="max-w-7xl mx-auto mt-40 md:mt-20 ">
            <h2 className="text-white font-bold text-3xl mb-12" >Příklady  <br/> realizovaných projektů </h2>
            <p className="text-white mb-0 "  >ReX náboráři uskuteční i ty nejtěžší přijímací řízení. Naše komunita sdružuje experty, kteří se specializují na všechna odvětví. Najdeme pro Vás zaměstnance bez ohledu na to, zda potřebujete do svého týmu 1, 10 nebo 100 lidí.</p>
            <Slider {...settings} className="relative -mx-10" >

            <div className="mt-32 px-10 group hover:scale-105 transition-all">
                <div className="example mb-32  object-fill relative aspect-[3/4] border-[12px] border-white">
                    <StaticImage quality={100} className="shadow-xl absolute inset-0 " src={'../../images/examples/monika-k.png'}/>
                    <div className="absolute flex flex-col justify-end inset-0 bg-purple bg-opacity-80 text-white px-8 py-7 w-full opacity-0 group-hover:opacity-100 transition-all">
                        <h3 className="mb-4 font-bold text-md">Junior účetní</h3>
                        <p className="text-xs mb-6">Zodpovídá za měsíční účetní uzávěrky, vypořádání ZUS [polská obdoba ČSSZ], GUS [polský Ústřední statistický úřad] a Urzędu Skarbowego [polský finanční úřad], mzdovou agendu, zúčtování zaměstnanců, archivace dokumentace, práce s [polskými] programy Płatnik a Progmann - Kadry, Przelewy.</p>
                        <p className="text-xs font-bold">Kandidáty na tuto pozici nejčastěji získáváme v Poznani, Wroclawi a Varšavě za cca 7 000 PLN.</p>
                    </div>
                    <div className="absolute bottom-0 bg-purple bg-opacity-80 w-full text-white px-8 py-7 group-hover:hidden">
                        <h3 className="mb-4 font-bold">Monika – Junior účetní – Cena za nábor – 7000 PLN</h3>
                        <p className="text-sm">Vedení účetnictví a řízení lidských zdrojů firem s úplným i zjednodušeným účetnictvím.</p>
                    </div>
                    <StaticImage className="absolute right-10 -top-14" src={'../../images/accent.png'} />
                </div>
            </div>

            <div className="mt-32 px-10 group hover:scale-105 transition-all">
                <div className="example mb-32  object-fill relative aspect-[3/4] border-[12px] border-white">
                    <StaticImage quality={100} className="shadow-xl absolute inset-0 " src={'../../images/examples/anna.png'}/>
                    <div className="absolute flex flex-col justify-end inset-0 bg-purple bg-opacity-80 text-white px-8 py-7 w-full opacity-0 group-hover:opacity-100 transition-all">
                        <h3 className="mb-4 font-bold text-md">Zákaznický servis se slovinštinou</h3>
                        <p className="text-xs mb-6">Zodpovídá za komplexní vyřizování objednávek a reklamací internetového obchodu. Telefonická a e-mailová služba pro slovinské zákazníky, poradenství ohledně nabízeného produktu.</p>
                        <p className="text-xs font-bold">Kandidáty na tuto pozici nejčastěji získáváme ve Varšavě za cca 8 500 PLN.</p>
                    </div>
                    <div className="absolute bottom-0 bg-purple bg-opacity-80 w-full text-white px-8 py-7 group-hover:hidden">
                        <h3 className="mb-4 font-bold">Anna, zákaznický servis se slovinštinou – Cena za nábor – 8500 PLN</h3>
                        <p className="text-sm">E-mailový a telefonický kontakt se zákazníky, kteří zakoupili produkt klienta.</p>
                    </div>
                    <StaticImage className="absolute right-10 -top-14" src={'../../images/accent.png'} />
                </div>
            </div>

            <div className="mt-32 px-10 group hover:scale-105 transition-all">
                <div className="example mb-32  object-fill relative aspect-[3/4] border-[12px] border-white">
                    <StaticImage quality={100} className="shadow-xl absolute inset-0 " src={'../../images/examples/urszula.png'}/>
                    <div className="absolute flex flex-col justify-end inset-0 bg-purple bg-opacity-80 text-white px-8 py-7 w-full opacity-0 group-hover:opacity-100 transition-all">
                        <h3 className="mb-4 font-bold text-md">People Advisor </h3>
                        <p className="text-xs mb-6">HR specialista. Zodpovídá za celý cyklus zaměstnávání zaměstnance a poradenství v oblasti pracovního práva.</p>
                        <p className="text-xs font-bold">Kandidáty na tuto pozici nejčastěji získáváme ve Varšavě za cca 17 000 PLN.</p>
                    </div>
                    <div className="absolute bottom-0 bg-purple bg-opacity-80 w-full text-white px-8 py-7 group-hover:hidden">
                        <h3 className="mb-4 font-bold">Urszula – People Advisor – Cena za nábor – 17 000 PLN.</h3>
                        <p className="text-sm">Support for employees in administrative and HR matters.</p>
                    </div>
                    <StaticImage className="absolute right-10 -top-14" src={'../../images/accent.png'} />
                </div>
            </div>

            <div className="mt-32 px-10 group hover:scale-105 transition-all">
                <div className="example mb-32  object-fill relative aspect-[3/4] border-[12px] border-white">
                    <StaticImage quality={100} className="shadow-xl absolute inset-0 " src={'../../images/examples/adam.png'}/>
                    <div className="absolute flex flex-col justify-end inset-0 bg-purple bg-opacity-80 text-white px-8 py-7 w-full opacity-0 group-hover:opacity-100 transition-all">
                        <h3 className="mb-4 font-bold text-md">Obchodní zástupce</h3>
                        <p className="text-xs mb-6">Zodpovídá za plnění cílů, péče o stávající zákazníky a často získávání nových zákazníků.</p>
                        <p className="text-xs font-bold">Kandidáty na tuto pozici nejčastěji získáváme v celém Polsku za cca 10 000 PLN.</p>
                    </div>
                    <div className="absolute bottom-0 bg-purple bg-opacity-80 w-full text-white px-8 py-7 group-hover:hidden">
                        <h3 className="mb-4 font-bold">Adam – Obchodní zástupce – Cena za nábor – 10 000 PLN</h3>
                        <p className="text-sm">Prodej služeb společnosti a její zastupování při styku se zákazníky.</p>
                    </div>
                    <StaticImage className="absolute right-10 -top-14" src={'../../images/accent.png'} />
                </div>
            </div>

            <div className="mt-32 px-10 group hover:scale-105 transition-all">
                <div className="example mb-32  object-fill relative aspect-[3/4] border-[12px] border-white">
                    <StaticImage quality={100} className="shadow-xl absolute inset-0 " src={'../../images/examples/ewa.png'}/>
                    <div className="absolute flex flex-col justify-end inset-0 bg-purple bg-opacity-80 text-white px-8 py-7 w-full opacity-0 group-hover:opacity-100 transition-all">
                        <h3 className="mb-4 font-bold text-md">Zákaznický servis s němčinou</h3>
                        <p className="text-xs mb-6">Zodpovídá za zadávání dat o objednávkách do SAP, řešení aktuálních zákaznických problémů, informování zákazníků o stavu jejich objednávek.</p>
                        <p className="text-xs font-bold">Kandidáty na tuto pozici nejčastěji získáváme v celém Polsku za cca 8 000 PLN.</p>
                    </div>
                    <div className="absolute bottom-0 bg-purple bg-opacity-80 w-full text-white px-8 py-7 group-hover:hidden">
                        <h3 className="mb-4 font-bold">Ewa, zákaznický servis s němčinou – Cena za nábor – 8000 PLN</h3>
                        <p className="text-sm">Telefonický a e-mailový kontakt s firemní klientelou ohledně objednávek.</p>
                    </div>
                    <StaticImage className="absolute right-10 -top-14" src={'../../images/accent.png'} />
                </div>
            </div>

            <div className="mt-32 px-10 group hover:scale-105 transition-all">
                <div className="example mb-32  object-fill relative aspect-[3/4] border-[12px] border-white">
                    <StaticImage quality={100} className="shadow-xl absolute inset-0 " src={'../../images/examples/tomek.png'}/>
                    <div className="absolute flex flex-col justify-end inset-0 bg-purple bg-opacity-80 text-white px-8 py-7 w-full opacity-0 group-hover:opacity-100 transition-all">
                        <h3 className="mb-4 font-bold text-md">Customer Quality Engineer</h3>
                        <p className="text-xs mb-6">Zodpovídá za dohled nad plněním požadavků zákazníků v organizaci. Správa zákaznických reklamací (8D reporty + sledování aktivit). Příprava analýz a zpráv o kvalitě. Příprava PPAP pro změny v sériové výrobě.</p>
                        <p className="text-xs font-bold">Kandidáty na tuto pozici nejčastěji získáváme v celém Polsku za cca 12 000 PLN.</p>
                    </div>
                    <div className="absolute bottom-0 bg-purple bg-opacity-80 w-full text-white px-8 py-7 group-hover:hidden">
                        <h3 className="mb-4 font-bold">Tomek, Customer Quality Engineer – Cena za nábor – 12 000 PLN</h3>
                        <p className="text-sm">První obranná linie při řešení reklamací zákazníků.</p>
                    </div>
                    <StaticImage className="absolute right-10 -top-14" src={'../../images/accent.png'} />
                </div>
            </div>

            <div className="mt-32 px-10 group hover:scale-105 transition-all">
                <div className="example mb-32  object-fill relative aspect-[3/4] border-[12px] border-white">
                    <StaticImage quality={100} className="shadow-xl absolute inset-0 " src={'../../images/examples/monika-c.png'}/>
                    <div className="absolute flex flex-col justify-end inset-0 bg-purple bg-opacity-80 text-white px-8 py-7 w-full opacity-0 group-hover:opacity-100 transition-all">
                        <h3 className="mb-4 font-bold text-md">C# Developer</h3>
                        <p className="text-xs mb-6">Tvorba softwaru pro vlastní aktualizaci dat.
                            Zavádění aktualizací softwaru podle obchodních potřeb.
                            Kontakt s obchodem.</p>
                        <p className="text-xs font-bold">Kandidáty na tuto pozici nejčastěji získáváme v celém Polsku za cca 12 000 PLN.</p>
                    </div>
                    <div className="absolute bottom-0 bg-purple bg-opacity-80 w-full text-white px-8 py-7 group-hover:hidden">
                        <h3 className="mb-4 font-bold">Monika – C# Developer – Cena za nábor – 25 000 PLN</h3>
                        <p className="text-sm">Programování softwaru pro mechanická a hydraulická zařízení, která se vyskytují ve vrtném otvoru.</p>
                    </div>
                    <StaticImage className="absolute right-10 -top-14" src={'../../images/accent.png'} />
                </div>
            </div>

                
            </Slider>   
           
        </div>
    </section>    
  )
}

export default Examples