import * as React from "react"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const TestimonialsArray = [
    {
        content: "Na platformu ReX jsem se chtěl jen podívat, ale už jsem zůstal. Je to už půl roku. To je spousta času, během kterého jsem se účastnil mnoha projektů z různých branží. Skvělé zkušenosti, dokonalá spolupráce a komunikace s ReX Leadery!",
        author: 'Mateusz Biegała',
        authorBio: ""
    },
    // {
    //     content: "Working with the ReX platform gives me what I expect in a partnership – freedom. The ReX platform suits me because I can choose interesting projects on my own, I can immediately see specific information about the client’s requirements, I get a clear quote for the project, and the invoice is paid on time and without any communication problems. The ReX platform is also right for me because I can choose between industries and recruit in different areas to gain new experiences and add variety to my work. And most of all, because there are real people behind it – ReX Leaders, who are ready to cooperate, open-minded and professional. The ReX platform is for me because no one tells me what time, how long and where to work. It’s intuitive, easy to use and read, and gives me the opportunity to participate in training courses and gain new knowledge. This is what the ReX platform is to me and, above all, the Wonderful People behind it. A job that’s a passion. A passion that’s a job. Highly recommended!",
    //     author: 'Monika Witkowska',
    //     authorBio: ""
    // },
    {
        content: "Na platformu ReX jsem se chtěl jen podívat, ale už jsem zůstal. Je to už půl roku. To je spousta času, během kterého jsem se účastnil mnoha projektů z různých branží. Skvělé zkušenosti, dokonalá spolupráce a komunikace s ReX Leadery! Doporučuji!",
        author: 'Wojciech Chróściński',
        authorBio: ""
    },
    {
        content: "Doporučuji spolupráci v rámci projektu ReX Partner. Transparentní podmínky, realizovatelné projekty, plná podpora ze strany ReX Leadera, a k tomu atraktivní odměny a možnost přizpůsobení hodin mým dalším činnostem – to jsou hlavní, ale ne jediné výhody tohoto programu. ",
        author: 'Michał Magowski',
        authorBio: ""
    },
    // {
    //     content: "I’ve been working as a recruiter for 6 months. Despite having more than 20 years of extensive experience in HR structures, I had to learn basic tools. Social networks, recruiter software, invaluable training courses and leadership support – all this has proved to be an important basis for work. After these 6 months, it’s become easier and easier for me to reach out to candidates, and to use various tools to get them and meet fantastic people. I hope that the day when I get the salary for the first employment will come soon. Certainly, a considerable difficulty comes from combining a permanent job with freelance work. You need to have a strict daily schedule and, very often, act quickly. In such situations, I would modify some rigorous recruitment procedures. Simply for the sake of supporting the recruiters and giving them a chance to complete their projects. Such measures can definitely improve the quality of work and the company’s reputation. Why, despite not getting paid, don’t I give up on freelancing? – at least for the time being :) It’s invaluable for me to gain some solid experience. Curiosity about the world and people is another important aspect. Creativity in reaching out to a prospective employee has proved to be an invaluable skill. What’s most important to me, however, is the possibility to talk to the candidates, make valuable new contacts and get thanks from the candidates, even if they’re not hired. This is a great sense of satisfaction and attentiveness for the recruiter.",
    //     author: 'Best regards, Eliza ',
    //     authorBio: ""
    // },
    
]
const Testimonials = () => {
    var settings = {
        dots: false,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ]
    };
  return (
    <section  className="relative overflow-visible object-none pt-24 z-10">
            <div className="overflow-hidden pb-52">
                <div className="max-w-7xl mx-auto">
            <Slider {...settings} className="testimonials-slider overflow-visible relative -mx-5">
                {TestimonialsArray.map((testimonial) => (
                <div className="px-5">
                <div className="min-h-[420px] single-slide-inner bg-teal text-dark-gray p-10 border-4 border-purple transition-colors flex flex-col justify-between">
                    <p className="mb-10">{testimonial.content}</p>
                    <div>
                    <p className="font-bold text-right justify-self-end self-end">{testimonial.author}</p>
                    <p className="text-right justify-self-end self-end">{testimonial.authorBio}</p>
                    </div>
                </div>
                </div>    
                ))}
            </Slider>
            </div>
        </div>
    </section>
        
  )
}

export default Testimonials;