import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const HowWeWork = () => {
  return (
    <section id="how-do-we-operate" className="relative overflow-visible object-none py-24 ">
        <div className="max-w-7xl mx-auto">
            <h2 className="text-center text-3xl font-bold text-dark-gray mb-20">Jak <span className="bg-gradient-to-br from-purple to-teal text-transparent bg-clip-text">fungujeme</span>?</h2>
            <div className="grid lg:grid-cols-3 gap-20 xl:-mx-20">
                <div className="how-we-work-element text-center group">
                    <div className="text-[120px]  block group-hover:text-teal group-hover:scale-125 origin-center transition-all">1</div>
                    <h3 className=" font-bold mb-8 group-hover:text-purple">Zadání projektu klientem</h3>
                    <p className="font-light text-dark-gray text-md">Záleží nám na Vašem čase, takže se nemusíte nikde registrovat. Stačí, když nám pošlete popis pracovní pozice a zodpovíte pár jednoduchých otázek.</p>
                </div>
                <div className="how-we-work-element text-center group">
                    <div className="text-[120px]  block group-hover:text-teal group-hover:scale-125 origin-center transition-all">2</div>
                    <h3 className=" font-bold mb-8 group-hover:text-purple">Výběr náborářů a okamžité zahájení činnosti</h3>
                    <p className="font-light text-dark-gray text-md">Pracují pro Vás kvalifikovaní ReX náboráři, kteří znají specifika Vašeho odvětví a lokality, a proto Vám poskytnou vhodné kandidáty. Náboráři se okamžitě pustí do práce.</p>
                </div>
                <div className="how-we-work-element text-center group">
                    <div className="text-[120px]  block group-hover:text-teal group-hover:scale-125 origin-center transition-all">3</div>
                    <h3 className=" font-bold mb-8 group-hover:text-purple">Nabídka kandidátů a vyúčtování projektu</h3>
                    <p className="font-light text-dark-gray text-md">Již po několika dnech obdržíte první CV odpovídajících kandidátů. Platíte nám pouze tehdy, když doporučeného kandidáta zaměstnáte. Podmínky spolupráce jsou transparentní a nejsou zde žádné skryté náklady.</p>
                </div>
            </div>
            
           
        </div>
        
    </section>
  )
}

export default HowWeWork