import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import ContactForm from "./contactForm"
const Download = () => {
  return (
    <section id="pobierz-ebook" className="bg-light-pink">
        <div className="max-w-7xl mx-auto grid md:grid-cols-3 gap-24">
            <div className="z-10 relative col-span-2 flex flex-col justify-end mr-24 text-lg">
                <p className="text-purple mb-12">
                Jak na nábor specialistů a manažerů? <br/>
                Metaversum v náboru- přicházející trend anebo vzdálená budoucnost? <br/>
                Crowdstaffing, contracting, RPO- jak populární jsou tyto služby?
                </p> 
                <p className="text-purple font-bold mb-10">Stáhněte si report publikovaný Antalem společně s ReX s názvem „Trendy v náboru profesionálů a manažerů” a najdete nejnovější výsledky průzkumu společně s komentáři od odborníků!</p>
            </div>
            <div className="flex justify-center items-center px-12">
                <StaticImage className="" src={'../../images/Rex-mockup-klienci.png'} />
            </div>
        </div>
        <div className="max-w-7xl mx-auto">
            <ContactForm />
        </div>
        
    </section>
  )
}

export default Download